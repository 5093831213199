import { ResourceUrlEnum } from '@/resource/enum';
import { FeedbackDetail, FeedbackList, FeedbackQuery, SaveFeedback } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';
import { handleDownload } from '@/utils';

class FeedbackService {
  /**
   * 获取项次详情
   * @param id
   * @returns
   */
  public getDetails(id: number): Promise<FeedbackDetail> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<FeedbackDetail>(`${ResourceUrlEnum.feedback}`, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(query: Partial<FeedbackQuery>, paging: Paging): Promise<ApiResponse<Array<FeedbackList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    Object.assign(cloneQuery, paging);
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<FeedbackList>>(`${ResourceUrlEnum.feedback}/listPage`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 修改配置信息
   * @param params
   * @returns
   */
  public post(params: SaveFeedback): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<SaveFeedback>(`${ResourceUrlEnum.feedback}`, params)
        .then((res: ApiResponse<SaveFeedback>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导出数据
   * @param query
   * @returns
   */
  public export(query: FeedbackQuery, idList: Array<number>): Promise<Blob> {
    const cloneQuery = {};
    Object.assign(cloneQuery, query);
    Object.assign(cloneQuery, { idList: idList });
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.feedback}/export`, cloneQuery, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const feedbackService = new FeedbackService();
export default feedbackService;
